<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-select
                v-model="options.product"
                :disabled="type == 'edit'"
                dense
                :label="$t('select_product_*')"
                :items="['oem', 'fleet']"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant"
                :disabled="type == 'edit'"
                dense
                :loading="tenantLoading"
                :items="type == 'edit' ? tenantsList : tenantLIST"
                item-text="name"
                item-value="id"
                :label="
                  options.product == 'fleet'
                    ? $t('select_fleet_*')
                    : $t('select_tenant_*')
                "
                :rules="[rules.required]"
                @change="checkTenant"
              />
            </v-col>
            <template v-for="(cat, i) in options.categories">
              <template>
                <v-col
                  :key="i + '-cat'"
                  cols="12"
                  sm="12"
                  class="add-more-category"
                >
                  <v-select
                    v-model="options.categories[i]"
                    dense
                    :items="
                      catOptions.filter(
                        (r) =>
                          r == options.categories[i] ||
                          !options.categories.includes(r)
                      )
                    "
                    hide-selected
                    :label="$t('category_name_*')"
                    :rules="[rules.required]"
                  />

                  <v-text-field
                    v-if="options.categories[i] == 'other'"
                    v-model="options.otherName"
                    dense
                    :label="$t('category_name_*')"
                    :rules="[rules.required]"
                  />
                  <div>
                    <v-btn
                      v-if="i + 1 == options.categories?.length"
                      color="primary"
                      small
                      @click="options.categories.push('')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                      v-else
                      color="danger"
                      small
                      @click="options.categories.splice(i, 1)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </template>
            </template>

            <v-col
              cols="6"
              sm="6"
            >
              <v-text-field
                v-if="['edit', 'veiw'].includes(type)"
                v-model="options.name"
                dense
                :disabled="readOnly"
                :label="$t('category_name_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {},
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {
        categories: [""],
      },
      catOptions: [
        "Bulbs",
        "Electrical",
        "Filters",
        "Fluids",
        "Brakes & Tires",
        "Windshield & Wipers",
        "other",
      ],

      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      //
    }),
    tenantLIST() {
      if (this.options.product == "oem") {
        return this.tenantsOEM;
      } else {
        return this.tenantsFLEET;
      }
    },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (val) {
    //     // this.checkTenant(val);
    //     if (this.type == "create") this.fetchDependents(val);
    //   },
    // },
  },
  async mounted() {
    if (this.type == "create") {
      if (this.authUser.isAdmin) {
        this.fetchDependents(this.authUser.tenant_id);
      } else {
        this.options.tenant_id = this.authUser.tenant_id;
      }
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      this.fetchDependents(this.options.tenant_id);
    }
  },
  methods: {
    saveItem() {
      const param = { ...this.options };

      const otName = param.otherName;
      param.categories = this.options.categories.map((r) =>
        r == "other" ? (r = otName) : r
      );
      if (this.$refs.form.validate()) {
        this.$emit("onsave", param);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

    async fetchDependents() {
      // this.getVehicleList();
    },

    checkTenant() {
      // this.getVehicleList();
    },

    //
  },
};
</script>
<style lang="sass" scoped>
.add-more-category
  display: flex
  justify-content: space-between
  gap: 0.5rem
  :deep button
    margin: 0 !important
</style>
