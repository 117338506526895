var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-card',[(_vm.title)?_c('v-card-title',[_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.authUser.isAdmin)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"disabled":_vm.type == 'edit',"dense":"","label":_vm.$t('select_product_*'),"items":['oem', 'fleet'],"rules":[_vm.rules.required]},model:{value:(_vm.options.product),callback:function ($$v) {_vm.$set(_vm.options, "product", $$v)},expression:"options.product"}})],1):_vm._e(),(_vm.authUser.isAdmin)?_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.type == 'edit',"dense":"","loading":_vm.tenantLoading,"items":_vm.type == 'edit' ? _vm.tenantsList : _vm.tenantLIST,"item-text":"name","item-value":"id","label":_vm.options.product == 'fleet'
                  ? _vm.$t('select_fleet_*')
                  : _vm.$t('select_tenant_*'),"rules":[_vm.rules.required]},on:{"change":_vm.checkTenant},model:{value:(_vm.options.tenant),callback:function ($$v) {_vm.$set(_vm.options, "tenant", $$v)},expression:"options.tenant"}})],1):_vm._e(),_vm._l((_vm.options.categories),function(cat,i){return [[_c('v-col',{key:i + '-cat',staticClass:"add-more-category",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"dense":"","items":_vm.catOptions.filter(
                      (r) =>
                        r == _vm.options.categories[i] ||
                        !_vm.options.categories.includes(r)
                    ),"hide-selected":"","label":_vm.$t('category_name_*'),"rules":[_vm.rules.required]},model:{value:(_vm.options.categories[i]),callback:function ($$v) {_vm.$set(_vm.options.categories, i, $$v)},expression:"options.categories[i]"}}),(_vm.options.categories[i] == 'other')?_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('category_name_*'),"rules":[_vm.rules.required]},model:{value:(_vm.options.otherName),callback:function ($$v) {_vm.$set(_vm.options, "otherName", $$v)},expression:"options.otherName"}}):_vm._e(),_c('div',[(i + 1 == _vm.options.categories?.length)?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.options.categories.push('')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_c('v-btn',{attrs:{"color":"danger","small":""},on:{"click":function($event){return _vm.options.categories.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)]]}),_c('v-col',{attrs:{"cols":"6","sm":"6"}},[(['edit', 'veiw'].includes(_vm.type))?_c('v-text-field',{attrs:{"dense":"","disabled":_vm.readOnly,"label":_vm.$t('category_name_*'),"rules":[_vm.rules.required]},model:{value:(_vm.options.name),callback:function ($$v) {_vm.$set(_vm.options, "name", $$v)},expression:"options.name"}}):_vm._e()],1)],2)],1)],1),_c('v-card-actions',[(!_vm.readOnly)?_c('small',[_vm._v(_vm._s(_vm.$t("indicates_required_field")))]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),(!_vm.readOnly)?_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.loading},on:{"click":_vm.saveItem}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }